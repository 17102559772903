<template>
  <el-dialog
    v-bind="{title: '签收砂浆量', width: '430px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form
      ref="form"
      label-width="105px"
      :model="submitForm"
      :rules="rules"
    >
      <el-form-item label="发货净重(吨)">
        <el-input
          v-if="editData"
          v-model.trim="editData.weight"
          disabled
          class="w-250"
        />
      </el-form-item>

      <el-form-item label="签收净重(吨)" prop="signWeight">
        <el-input-number
          v-model.number="submitForm.signWeight"
          :controls="false"
          :precision="2"
          placeholder="请输入签收净重"
          clearable
          class="w-250"
        />
      </el-form-item>

      <el-form-item label="回车重量(吨)" prop="backCarWeight">
        <el-input-number
          v-model.number="submitForm.backCarWeight"
          :controls="false"
          :precision="2"
          clearable
          placeholder="请输入回车重量"
          class="w-250"
        />
      </el-form-item>

      <el-form-item label="签收时间" prop="signTime">
        <el-date-picker
          v-model="submitForm.signTime"
          value-format="yyyy-MM-dd"
          type="datetime"
          placeholder="选择签收时间"
          class="w-250">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="运距(km)" prop="distance">
        <el-input
          v-if="editData"
          v-model="editData.distance"
          :controls="false"
          clearable
          disabled
          class="w-250"
        />
      </el-form-item>

      <el-form-item label="车费(元)" prop="carFee">
        <el-input-number
          v-model.number="submitForm.carFee"
          :controls="false"
          :precision="2"
          clearable
          placeholder="请输入车费"
          class="w-250"
        />
      </el-form-item>

      <el-form-item label="备注" prop="remark">
        <el-input
          type="textarea"
          v-model.trim="submitForm.remark"
          clearable
          placeholder="请输入备注"
          class="w-250"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { signShipmentWeight } from '@/apis/prod_manage/shipDetailShaJiang'
export default {
  props: {
    visible: { type: Boolean, default: false, require: true },
    editData: { type: Object },
    type: { type: Number }
  },
  computed: {
    visibleDialog: {
      set (newValue) { this.$emit('update:visible', newValue) },
      get () { return this.visible }
    }
  },
  data () {
    return {
      loading: false,
      submitForm: {
        backCarWeight: '', // 回车重量
        carFee: '', // 车费
        compId: '', // 企业id
        remark: '', // 备注
        serialID: '', // 原始数据主键
        signTime: '', // 签收时间
        signWeight: 0 // 签收净重
      },
      rules: {
        signWeight: [
          { required: true, trigger: 'change', message: '请输入签收净重' },
          { trigger: 'change', validator: this.verifySignWeight }
        ],
        backCarWeight: { required: true, trigger: 'change', message: '请输入回车重量' },
        carFee: { required: true, trigger: 'change', message: '请输入车费' },
        signTime: { required: true, trigger: 'change', message: '请选择签收时间' }
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  methods: {
    submit () {
      const submitForm = { ...this.submitForm }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          signShipmentWeight(submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('创建成功！')
              this.$emit('refresh')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    // 初始化表单
    handlerOpen () {
      const submitForm = this.submitForm
      const editData = this.editData
      if (!editData) return
      submitForm.signWeight = editData.confirmWeight
      submitForm.signTime = editData.confirmTime
      Object.keys(submitForm).forEach(key => {
        const value = editData[key]
        if (value !== undefined && value !== null) submitForm[key] = value
      })
    },

    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
    },

    verifySignWeight (rule, value, callback) {
      const sendTray = this.editData.weight
      if (value > sendTray) {
        callback(new Error('签收净重 不能大于 发货净重'))
      } else {
        callback()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-number.is-without-controls .el-input__inner {
 text-align: left;
}
</style>
