<template>
  <page-container>

    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="发货日期:">
        <el-date-picker
          v-model="sendDate"
          clearable
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="工程名称:">
        <el-input v-model.trim="searchForm.projName" clearable placeholder="请输入" />
      </el-form-item>
      <el-form-item label="施工单位:">
        <el-input v-model.trim="searchForm.cusName" clearable placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="企业名称:">
        <el-input v-model.trim="searchForm.compName" clearable placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="技术要求:">
        <el-select v-model="searchForm.technicalRequirement" clearable filterable placeholder="请选择" >
          <el-option v-for="item in technicalRequirements" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
    </BaseSearchForm>

    <!-- 表格 -->
    <BaseTable
      id="shipDetailShaJiang"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData"
      :footer-method="footerMethod"
      show-footer
    >
      <template #buttons>
        <el-button size="small" icon="el-icon-download" type="primary" @click="exportTable">导出</el-button>
      </template>
      <vxe-column type="seq" width="80" align="center" title="序号" fixed="left"></vxe-column>
      <vxe-column field="checkStatusName" title="状态" min-width="80">
        <template v-slot="{ row }">
          {{row.thisYearTheoryWeight}}
        </template>
      </vxe-column>
      <vxe-column field="typeName" title="类型" min-width="80" />
      <vxe-column field="tankNumber" title="罐号" min-width="100" />
      <vxe-column field="createTime" title="发货时间" min-width="165" />
      <vxe-column field="taskID" title="任务单号" min-width="177" />
      <vxe-column field="scheduleID" title="发货号" min-width="100" />
      <vxe-column field="bajarid" title="生产线" min-width="80" />
      <vxe-column field="cusName" title="施工单位" min-width="220" />
      <vxe-column field="projName" title="工程名称" min-width="220" />
      <vxe-column field="compName" title="企业名称" min-width="220" />
      <vxe-column field="technicalRequirement" title="技术要求" min-width="100" />
      <vxe-column field="weight" title="净重(吨)" min-width="80" />
      <vxe-column field="confirmWeight" title="签收净重(吨)" min-width="120" >
        <template v-slot="{ row }">
          <span @click="handlerWeight(row)" class="blue pointer underline">
            {{row.confirmWeight}}
          </span>
        </template>
      </vxe-column>
      <vxe-column field="sendTray" title="发出托盘" min-width="100" />
      <vxe-column field="receiveTray" title="回收托盘" min-width="100">
        <template v-slot="{ row }">
          <span @click="handlerTray(row)" class="blue pointer underline">
            {{row.receiveTray}}
          </span>
        </template>
      </vxe-column>
      <vxe-column field="taskPlanWeight" title="已供应量(吨)" min-width="120" />
      <vxe-column field="carFee" title="车费" min-width="80" />
      <vxe-column field="licPlate" title="车号" min-width="100" />
      <vxe-column field="driverName" title="驾驶员" min-width="80" />
      <vxe-column field="deliveryMethodName" title="提货方式" min-width="100" />
      <vxe-column field="mortarInfo" title="返回砂浆信息" min-width="120" />
      <vxe-column field="remark" title="备注" min-width="100" />
      <vxe-column title="小票打印" fixed="right" min-width="150">
        <template v-slot="{ row }">
          <el-button type="primary" @click="printTicket(row)">打印</el-button>
        </template>
      </vxe-column>
    </BaseTable>

    <div>
      <check-tray
        :visible.sync="trayVisible"
        :edit-data.sync="editData"
        @refresh="initList"
      />
      <check-weight
        :visible.sync="weightVisible"
        :edit-data.sync="editData"
        @refresh="initList"
      />
      <!-- 打印票据内容 -->
      <div ref="printContent" class="printContent">
        <h3>{{printData.compName}}</h3>
        <h3>送   货   单</h3>
        <div class="fields-box">
          <span style="flex:1;">驾驶员: {{printData.driverName}}</span>
          <span style="flex:1;">车牌号: {{printData.licPlate}}</span>
          <span style="flex:2;">送货单编号: {{printData.scheduleID}}</span>
        </div>
        <table border="1" cellspacing="0" cellpadding="10" >
          <tr>
            <td style="width: 100px;">工程名称</td>
            <td colspan="3">{{printData.projName}}</td>
          </tr>
          <tr>
            <td style="width: 100px;">购货单位</td>
            <td>{{printData.cusName}}</td>
            <td style="width: 100px;">工程地址</td>
            <td>{{printData.adress}}</td>
          </tr>
          <tr>
            <td style="width: 100px;">联系人</td>
            <td>{{printData.contactName}}</td>
            <td style="width: 100px;">联系人电话</td>
            <td>{{printData.contactPhone}}</td>
          </tr>
          <tr>
            <td style="width: 100px;">毛重(吨)</td>
            <td>{{printData.grossWeight}}</td>
            <td style="width: 100px;">供货日期</td>
            <td>{{printData.createTime}}</td>
          </tr>
          <tr>
            <td style="width: 100px;">皮重(吨)</td>
            <td>{{printData.tareWeight}}</td>
            <td style="width: 100px;">砂浆品种</td>
            <td>{{printData.mortarType}}</td>
          </tr>
          <tr>
            <td style="width: 100px;">净重(吨)</td>
            <td>{{printData.weight}}</td>
            <td style="width: 100px;">砂浆等级</td>
            <td>{{printData.technicalRequirement}}</td>
          </tr>
          <tr>
            <td style="width: 100px;">备注</td>
            <td colspan="3">{{printData.remark}}</td>
          </tr>
        </table>
        <div class="fields-box">
          <div style="flex:1;">收货人签字: </div>
          <div style="flex:1;">签收日期: </div>
          <div style="flex:1;">制单人: {{printData.createName}}</div>
          <div style="flex:1;">复核人: </div>
        </div>
      </div>
    </div>
  </page-container>
</template>

<script>
import CheckTray from './components/CheckTray'
import CheckWeight from './components/CheckWeight'
import {
  getShipmentList,
  exportShipmentList
} from '@/apis/prod_manage/shipDetailShaJiang'
import { exportToxlsx } from '@/utils/tools'
import BaseTable from '../../../components/common/BaseTable/index.vue'
export default {
  data () {
    return {
      technicalRequirements: [
        'WPM10',
        'WPM15',
        'DPM15散装',
        'WPM20',
        '抹灰PM10',
        '砌筑MM10',
        '抹灰PM15',
        'WP-G M7.5',
        'WPM5',
        'PM10+防水剂',
        '抹灰PM5',
        'WM M10',
        'WMM7.5',
        'WP-G M15',
        'DPM7.5',
        'DPM20',
        '地面SM15',
        'DPM15',
        'M15',
        'DPM10散装',
        'DMM10散装',
        'DM7',
        'DP M5',
        'DP M20'
      ],
      searchForm: {
        startCreateTime: '',
        endCreateTime: '',
        projName: '', // 工程名称
        technicalRequirement: '', // 技术要求
        compName: '',
        cusName: ''
      },
      // 表格相关
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 15,
        totalSize: 0
      },
      totalResult: {
        backCarWeight: 0, // 回车重
        confirmWeight: 0, // 签收净重
        differentWeight: 0, // 差量
        weight: 0 // 净重
      },
      loading: false,
      editData: {},
      weightVisible: false,
      trayVisible: false
    }
  },
  computed: {
    sendDate: {
      get () {
        const searchForm = this.searchForm
        if (searchForm.startCreateTime && searchForm.endCreateTime) {
          const startDate = searchForm.startCreateTime
          const endDate = searchForm.endCreateTime
          return [startDate, endDate]
        } else {
          return null
        }
      },
      set (newVal) {
        const searchForm = this.searchForm
        const [startDate, endDate] = newVal || ['', '']
        searchForm.startCreateTime = startDate
        searchForm.endCreateTime = endDate
      }
    },
    printData () {
      return this.editData || {}
    }
  },
  created () {
    this.initList()
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },
    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },
    async initList () {
      this.loading = true
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      const res = await getShipmentList(params)
      this.loading = false
      if (res.code == 200) {
        this.tableData = res.data.rows.map(item => {
          const status = { 1: '未审核', 2: '已审核' }
          const types = { 0: '未知', 1: '包装', 2: '散装' }
          const deliveryMethods = { 0: '未知', 1: '自提', 2: '非自提' }
          item.checkStatusName = status[item.checkStatus]
          item.typeName = types[item.type]
          item.deliveryMethodName = deliveryMethods[item.deliveryMethod]
          return item
        })
        this.tablePage.totalSize = res.data.totalSize
        this.totalResult = res.data.totalResult
      } else {
        this.$message.error(res.msg)
      }
    },
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },
    handlerWeight (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.weightVisible = true
    },
    handlerTray (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.trayVisible = true
    },
    async exportTable () {
      const searchForm = this.searchForm
      const res = await exportShipmentList(searchForm)
      exportToxlsx(res, '发货明细-砂浆')
    },
    printTicket (row) {
      const divEl = this.$refs.printContent
      this.editData = JSON.parse(JSON.stringify(row))
      this.$nextTick(() => {
        this.$XPrint({
          sheetName: '发货单',
          style: `
            h3 { text-align:center; }
            table { width:100%; border-collapse:collapse; }
            .fields-box { line-height: 2.5em; text-indent: 10px; display: flex; width: 100%; }
          `,
          content: divEl.innerHTML
        })
        this.editData = null
      })
    },
    footerMethod ({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if ([
            'confirmWeight',
            'backCarWeight',
            'differentWeight',
            'weight'
          ].includes(column.property)) {
            return this.totalResult[column.property]
          }
          return null
        })
      ]
    }
  },
  components: {
    CheckTray,
    CheckWeight,
    BaseTable
  }
}
</script>
<style lang="scss" scoped>
.printContent {
  position: fixed;
  transform: translateY(1000px);
  opacity: 0;
}
</style>
