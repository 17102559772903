import http from '@/utils/http'

// 发货明细列表
export function getShipmentList (p) {
  return http.get('/material/shipment/getShipmentList', p)
}

// 发货明细-签收净重
export function signShipmentWeight (p) {
  return http.post('/material/shipment/signShipmentWeight', p)
}

// 发货明细-托盘回收
export function signShipmentTray (p) {
  return http.post('/material/shipment/signShipmentTray', p)
}

// 发货明细-导出列表
export function exportShipmentList (p) {
  return http.postBlob('/material/shipment/exportShipmentList', p)
}

// 发货明细-审核发货(未审核/已审核)
export function checkShipment (p) {
  return http.post('/material/shipment/checkShipment', p)
}
